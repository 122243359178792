import { Suspense, lazy, useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { gentTheme } from "./stylesExtend/theme";
import GlobalStyles from "./stylesExtend/globalStyles";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
// import { useAppSelector } from './reducers/disPatchSelector';
// import { GeoProvider } from './components/selectRef/hook/useGeofenceContext';
import { ReactQueryDevtools } from "react-query/devtools";
import Modal from "./components/modal/Modal";
import TokenExpiration from "./components/token-expiration/TokenExpiration";
import axios from "axios";
import { useAuthorize } from "./hooks/useAuthorize";
import {
  VIEW2DCANVAS,
  VIEWALERTS,
  VIEWREPORT,
  VIEWVIDEO,
  VIEWCHARTS,
} from "./utils/permissionsVariables";
//import Map from './pages/map/Map'

//const Tools = lazy(()=>import( './pages/tools/Tools'));
const NewPassword = lazy(() => import("./pages/newPassowrd/NewPassword"));
const Map = lazy(() => import("./pages/map/Map"));
const Login = lazy(() => import("./pages/login/Login"));
const ForgotPassword = lazy(
  () => import("./pages/forgetPassword/ForgotPassword")
);
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const DashboardContents = lazy(
  () => import("./pages/dashboardItems/DashboardContents")
);
const MapContent = lazy(() => import("./pages/mapContent/MapContent"));
const Canvas2D = lazy(() => import("./pages/canvas/Canvas2D"));
const Object2D = lazy(() => import("./pages/object2d/Object2D"));
const Chart = lazy(() => import("./pages/chart/Chart"));
const DashboardSummary = lazy(
  () => import("./pages/dashboard/DashboardSummary")
);
const Devices = lazy(() => import("./pages/dashboard/Devices"));
const AllCanvas = lazy(() => import("./pages/canvas/AllCanvas"));
const Create2DCanvas = lazy(() => import("./pages/canvas/Create2DCanvas"));
const DevicePosition = lazy(() => import("./pages/canvas/DevicePosition"));
const SignUp = lazy(() => import("./pages/sign-up/SignUp"));
const EmailVerification = lazy(
  () => import("./pages/email-verification/EmailVerification")
);
const Report = lazy(() => import("./pages/Report/Report"));
const Alerts = lazy(() => import("./pages/Alert/Alerts"));
const SendCommand = lazy(() => import("./components/command/SendCommand"));
const Plan = lazy(() => import("./pages/plans/Plan"));
const ManageUsers = lazy(() => import("./pages/manageUser/ManageUsers"));
const Billing = lazy(() => import("./pages/Billing/Billing"));
const Video = lazy(() => import("./pages/Video/Video"));
const VideoContent = lazy(
  () => import("./pages/Video/components/VideoContent")
);

function App() {
  //const auth = useAppSelector(state=> state.userReducer.isAuth)
  const [open, setOpen] = useState(false);
  const { isAuthorize } = useAuthorize();
  const canViewAlert = isAuthorize(VIEWALERTS);
  const canViewReports = isAuthorize(VIEWREPORT);
  const canView2DCanvas = isAuthorize(VIEW2DCANVAS);
  const canViewVideo = isAuthorize(VIEWVIDEO);
  const canViewChart = isAuthorize(VIEWCHARTS);
  useEffect(() => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          console.log("error", error);
          setOpen(true);
        }
      }
    );
  }, []);

  return (
    <Suspense fallback={"Loading..."}>
      <ThemeProvider theme={gentTheme}>
        <Router>
          <>
            <Modal
              title="Notification"
              open={open}
              setOpen={setOpen}
              toggle={() => setOpen((prev) => !open)}
              hideScroll="true"
            >
              <TokenExpiration handleModal={() => setOpen(false)} />
            </Modal>
            <GlobalStyles />
            <Routes>
              <Route path="/" element={<Navigate to="sign-in" replace />} />
              <Route path="sign-in" element={<Login />} />
              <Route path="sign-up" element={<SignUp />} />
              <Route path="verify-user" element={<EmailVerification />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password" element={<NewPassword />} />

              {/* NESTED ROUTES: DASHBOARD, MAPS, TOOLS ITEMS ROUTES*/}
              {/* <Route path='tools' element={<Tools/>}/> */}
              <Route path="dashboard" element={<DashboardContents />}>
                <Route path="/dashboard" element={<Dashboard />}>
                  <Route path="" element={<DashboardSummary />} />
                  <Route path="devices" element={<Devices />} />
                  {canView2DCanvas ? (
                    <Route path="canvas" element={<AllCanvas />} />
                  ) : null}
                  <Route path="upload-canvas" element={<Create2DCanvas />} />
                  <Route path="create-canvas" element={<DevicePosition />} />
                  {canViewReports ? (
                    <Route path="reports" element={<Report />} />
                  ) : null}
                  {canViewAlert ? (
                    <Route path="alerts" element={<Alerts />} />
                  ) : null}
                  <Route path="plans" element={<Plan />} />
                  <Route path="send-command" element={<SendCommand />} />
                  <Route path="manage-users" element={<ManageUsers />} />
                  <Route path="billing" element={<Billing />} />
                </Route>
                {canViewVideo && <Route path="video" element={<Video />} />}
                {/*NESTED ROUTES: MAP, CANVAS, 2D OBJECT, CHART ROUTES*/}
                <Route path="map-items" element={<Map />}>
                  <Route path="map" element={<MapContent />} />
                  <Route path="canvas2d" element={<Canvas2D />} />

                  <Route path="object2d" element={<Object2D />} />
                  {canViewChart && <Route path="chart" element={<Chart />} />}
                </Route>
              </Route>
            </Routes>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </>
        </Router>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
//import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined'; used in task compeleted

//To draw shaepes on the map use react-leaft-draw
//To print react-leaflet-print
