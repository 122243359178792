import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { getToken } from "../../hooks/getToken";
import { client } from "../../utils/axios-instance";
import { useDeviceTracking } from "../selectRef/hook/useGeofenceContext";

const url = process.env.REACT_APP_MONITOR_URL + "history";
type historyType = {
  sensorDataVsTime: [];
  summary: [];
};
type HistoryCtxType = {
  historyData: historyType;
  setHistoryData: Dispatch<SetStateAction<historyType>>;
};
const defaultHistoryContext: HistoryCtxType = {
  historyData: { sensorDataVsTime: [], summary: [] },
  setHistoryData: () => {}, // Placeholder function for default value
};

export const HistoryContext = createContext<HistoryCtxType>(
  defaultHistoryContext
);

export const HistoryProvider = ({ children }) => {
  const [historyData, setHistoryData] = useState<historyType>({
    sensorDataVsTime: [],
    summary: [],
  });

  return (
    <HistoryContext.Provider value={{ historyData, setHistoryData }}>
      {children}
    </HistoryContext.Provider>
  );
};

const useHistory = (startDate?: string, endDate?: string, imei?: string) => {
  const { historyData, setHistoryData } = useContext(HistoryContext);
  const [loading, setLoading] = useState(false);
  const { getTrackingPanel, panel } = useDeviceTracking();

  // let data = {
  //   start: '2022-09-04T10:26:06.009Z',
  //   end: '2025-09-04T10:26:06.009Z',
  //   imeis: ['868720061346247', '868720061343103', '868720061140947'],
  // };

  const getHistory = async () => {
    let data = {
      start: startDate ? new Date(startDate).toISOString() : "",
      end: endDate ? new Date(endDate).toISOString() : "",
      // imeis: imei,
      imeis: [imei],
    };
    let token = getToken();
    let request = client(token);
    setLoading(true);
    await request
      .post(url, data)
      .then((res) => {
        if (!panel.historyPart || panel.hideHistoryBody)
          getTrackingPanel("", true);
        if (res && res.data) {
          setHistoryData(res?.data?.data);
          localStorage.setItem("history", JSON.stringify(res?.data?.data));
        } else {
          localStorage.removeItem("history");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return {
    historyData,
    loading,
    getHistory,
  };
};

export default useHistory;
