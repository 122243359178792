import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Actions = {
  lat: number;
  lng: number;
  id: string;
  icon: string;
  desc?: any;
};
type POIInitState = {
  ids: string[];
  activePois: any[];
};
const initialState: POIInitState = {
  ids: [],
  activePois: [],
};

const poiSlice = createSlice({
  name: "poi",
  initialState: initialState,
  reducers: {
    handleActivePoi: (state, actions: PayloadAction<Actions>) => {
      let id = state.ids.includes(actions.payload.id);
      if (id) {
        state.activePois = state.activePois?.filter(
          (item) => item?.id !== actions.payload.id
        );
        state.ids = state.ids.filter((p) => p !== actions.payload.id);
      } else {
        state.activePois.push(actions.payload);
        state.ids.push(actions.payload.id);
      }
    },
    updateActivePoi: (state, action) => {
      const { poi } = action.payload;
      state.activePois = state.activePois.map(
        (item) =>
          item.id === poi._id
            ? {
                lat: poi?.location.lat,
                lng: poi?.location.lon,
                id: poi?._id,
                icon: poi?.iconTag,
                desc: { ...item },
              }
            : item // Compare item._id with poi._id
      );
    },

    deletePoi: (state, actions) => {
      let id = state.ids.includes(actions.payload.id);
      if (id) {
        state.activePois = state.activePois?.filter(
          (item) => item?.id !== actions.payload.id
        );
        state.ids = state.ids.filter((p) => p !== actions.payload.id);
      }
    },
    resetPois: (state) => {
      state.ids = initialState.ids;
      state.activePois = initialState.activePois;
    },
  },
});

export const { handleActivePoi, deletePoi, resetPois, updateActivePoi } =
  poiSlice.actions;
export default poiSlice.reducer;
