// Devices
export const VIEWDEVICE = 100200;
export const ADDDEVICE = 100201;
export const EDITDEVICE = 100202;
export const DELETEDEVICE = 100203;
export const GROUPDEVICE = "A";

// Alerts
export const VIEWALERTS = 101200;
export const ADDALERTS = 101201;
export const EDITALERTS = 101202;
export const DELETEALERTS = 101203;
export const GROUPALERTS = "B";

// Report
export const VIEWREPORT = 102200;
export const ADDREPORT = 102201;
export const EDITREPORT = 102202;
export const DELETEREPORT = 102203;
export const GROUPREPORT = "B";
export const NOTESREPORT =
  "Download/Generate Report works if Report is Enabled. The Add, Edit and Delete works on Saved Reports";

// Geofence
export const VIEWGEOFENCE = 103200;
export const ADDGEOFENCE = 103201;
export const EDITGEOFENCE = 103202;
export const DELETEGEOFENCE = 103203;
export const GROUPGEOFENCE = "B";

// POI
export const VIEWPOI = 104200;
export const ADDPOI = 104201;
export const EDITPOI = 104202;
export const DELETEPOI = 104203;
export const GROUPPOI = "B";

// Send Command
export const SENDCOMMAND = 105200;
export const GROUPSENDCOMMAND = "C";
export const NOTESSENDCOMMAND =
  "API called by Sensor Microservice to validate user before it sends command to devices needs to be updated for permissions";

// Map History
export const VIEWMAPHISTORY = 106200;
export const ADDMAPHISTORY = 106201;
export const EDITMAPHISTORY = 106202;
export const DELETEMAPHISTORY = 106203;
export const GROUPMAPHISTORY = "A";

// Maps
export const VIEWMAPS = 107200;
export const GROUPMAPS = "A";
export const NOTESMAPS = "Always enabled. This is also available in Plans.";

// 2D Canvas
export const VIEW2DCANVAS = 108200;
export const ADD2DCANVAS = 108201;
export const EDIT2DCANVAS = 108202;
export const DELETE2DCANVAS = 108203;
export const GROUP2DCANVAS = "C";

// 2D Object
export const VIEW2DOBJECT = 109200;
export const GROUP2DOBJECT = "C";

// Charts
export const VIEWCHARTS = 110200;
export const GROUPCHARTS = "C";

// Groups
export const VIEWGROUPS = 111200;
export const ADDGROUPS = 111201;
export const EDITGROUPS = 111202;
export const DELETEGROUPS = 111203;
export const GROUPGROUPS = "A";

// RFID
export const VIEWRFID = 112200;
export const ADDRFID = 112201;
export const EDITRFID = 112202;
export const DELETERFID = 112203;
export const GROUPRFID = "D";

// Calibration
export const VIEWCALIBRATION = 113200;
export const ADDCALIBRATION = 113201;
export const EDITCALIBRATION = 113202;
export const DELETECALIBRATION = 113203;
export const GROUPCALIBRATION = "C";

// Video
export const VIEWVIDEO = 114200;
export const GROUPVIDEO = "D";
export const NOTESVIDEO = "Enable/Disable the Video Feature";

// Audio
export const VIEWAUDIO = 115200;
export const GROUPAUDIO = "D";
export const NOTESAUDIO = "Enable/Disable the Audio Feature";

// Device Terminal
export const VIEWDEVICETERMINAL = 116200;
export const GROUPDEVICETERMINAL = "D";
export const NOTESDEVICETERMINAL =
  "Device Terminal Feature allows users to view data transmitted from their devices in real-time.";
