import { useAppSelector } from "../reducers/disPatchSelector";

export const useAuthorize = () => {
  const { body } = useAppSelector((state) => state.userReducer.currentUser);
  const permissions = body?.data.permissions;

  const isAuthorize = function (permissionCode) {
    if (permissions) return permissions.includes(permissionCode);

    return false;
  };

  return {
    isAuthorize,
  };
};
