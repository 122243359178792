import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { GeofenceProp } from "../../pages/map/useMapContext";

type Geoshape = {
  activeItems: any[];
  ids: string[];
  jsonData: GeofenceProp;
  selectedItem: [];
  displayGeo: boolean;
};
const initialState: Geoshape = {
  activeItems: [],
  ids: [],
  jsonData: {} as GeofenceProp,
  selectedItem: [],
  displayGeo: true,
};

const geofenceSlice = createSlice({
  name: "geofenceSlice",
  initialState: initialState,
  reducers: {
    // ectractGeodata: ()=>{

    // },

    checkItemsOnLoad: (state, action: PayloadAction<any>) => {
      // const isItemExist = state.activeItems.some(
      //   (item) => item._id === action.payload._id
      // );
      // if (!isItemExist) {
      //   state.activeItems = [...state.activeItems, action.payload];
      // }
    },
    getIdsOnLoad: (state, action: PayloadAction<any>) => {
      // // Check if the ID is already in the array
      // const isIdExist = state.ids.includes(action.payload);
      // // If not, add the ID to the array
      // if (!isIdExist) {
      //   state.ids = [...state.ids, action.payload];
      // }
    },
    toggleDisplayGeo: (state, action: PayloadAction<any>) => {
      const { payload } = action.payload;
      state.displayGeo = payload;
    },

    handleSelectItem: (state, action: PayloadAction<any>) => {
      let item = _.find(state.activeItems, action.payload);
      if (item) {
        state.activeItems = state.activeItems.filter(
          (d) => d?._id !== action.payload?._id
        );
      } else {
        state.activeItems = [...state.activeItems, action.payload];
        state.ids = [...state.ids, action.payload?._id];
      }
    },
    updateActiveItem: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      console.log(payload, state.activeItems);
      state.activeItems = state.activeItems.map(
        (item) => (item._id === payload._id ? payload : item) // Compare item._id with poi._id
      );
      console.log(state.activeItems);
    },
    editItem: (state, action: PayloadAction<any>) => {
      console.log("json data", action.payload);
      state.jsonData = action.payload;
    },

    getIds: (state, action: PayloadAction<any>) => {
      let id = state.ids.includes(action.payload);

      if (id) {
        state.ids = state.ids.filter((d) => d !== action.payload);
      } else {
        state.ids = [...state.ids, action.payload];
      }
    },
    deleteItem: (state, action: PayloadAction<any>) => {
      let item = action?.payload;

      state.activeItems = state.activeItems.filter((d) => d?._id !== item);
    },
  },
});

export const {
  handleSelectItem,
  editItem,
  getIds,
  deleteItem,
  checkItemsOnLoad,
  getIdsOnLoad,
  updateActiveItem,
  toggleDisplayGeo,
} = geofenceSlice.actions;
export default geofenceSlice.reducer;
